import { mapHelper } from 'common/utils'

// 流程状态
const status = [
  {
    text: '未发布',
    value: 0
  },
  {
    text: '已发布',
    value: 1
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

// 流程类型
const flowType = [
  {
    text: '物业流程',
    value: 1
  },
  {
    text: '招商流程',
    value: 2
  },
  {
    text: '后台流程',
    value: 3
  },
  {
    text: '外部流程',
    value: 4
  },
  {
    text: '产服流程',
    value: 5
  },
  {
    text: '表单流程',
    value: 6
  }
]

const {
  map: flowTypeMap,
  setOps: setFlowTypeOps
} = mapHelper.setMap(flowType)

export {
  statusMap,
  setStatusOps,
  flowTypeMap,
  setFlowTypeOps
}
