<template>
  <div class="WorkFlowList">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :hasOperateColumn="userType == 101"
    >
      <template #headerSlot>
        <v-button v-if="userType == 101" text="新增" permission="add" @click="createData" />
      </template>
      <template #searchSlot>
        <v-input label="流程名称" v-model="searchParams.flowName" />
        <v-select label="流程类型" v-model="searchParams.flowType" :options="flowTypeOps" />
        <v-select label="流程状态" v-model="searchParams.status" :options="statusOps" />
        <v-select2 label="创建人" v-model="searchParams.inuser" v-bind="updateUserParams" />
        <v-datepicker label="创建时间" type="rangedatetimer" :startTime.sync="searchParams.beginTime" :endTime.sync="searchParams.endTime" />
        <v-datepicker label="修改时间" type="rangedatetimer" :startTime.sync="searchParams.beginUpdateTime" :endTime.sync="searchParams.endUpdateTime" />
      </template>
      <template #operateSlot="scope">
        <v-button v-if="userType == 101" text="编辑" permission="update" type="text" @click="updateData(scope.row)" />
        <v-button v-if="userType == 101" text="设计" permission="design" type="text" @click="design(scope.row)" />
        <v-button v-if="userType == 101" text="发布" permission="publish" type="text" @click="publish(scope.row)" />
      </template>
    </list>
    <el-dialog
      custom-class="assignment-dialog"
      title="审批人"
      :modal-append-to-body="false"
      :visible.sync="assignmentDialogVisible"
      :before-close="assignmentDialogClose"
    >
      <div class="assignment-dialog-content" v-if="assignmentDialogVisible">
        <checkbox-plus type="radio" :value.sync="assigneeType" :options="assigneeTypeOps" />
        <div v-show="assigneeType == 1">
          <span>选择指定人：</span>
          <v-select2 style="display:inline-block;" v-model="assignmentDialogData.assignee" v-bind="updateUserParams" :width="300" />
        </div>
      </div>
      <div>
        <v-button text="保存" @click="assignmentDialogSave" />
      </div>
    </el-dialog>
    <el-dialog
      custom-class="work-flow-design-dialog"
      title="流程设计器"
      fullscreen
      :modal-append-to-body="false"
      :modal="false"
      :visible.sync="designDialogVisible"
      :before-close="handleClose"
    >
      <div class="work-flow-design-dialog-content" v-if="designDialogVisible">
        <iframe ref="workFlowDesign" width="100%" height="100%" :src="workFlowDesignSrc" style="border:none"></iframe>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryListURL, getUserListURL, saveDesignURL, deployDesignURL } from './api'
import { setStatusOps, statusMap, setFlowTypeOps, flowTypeMap } from './map'
import { CheckboxPlus } from 'components/bussiness'

export default {
  name: 'WorkFlowList',
  components: {
    CheckboxPlus
  },
  data () {
    return {
      token: this.$store.state.token,
      baseURL: API_CONFIG.controlBaseURL,
      pathname: window.location.pathname,
      searchUrl: queryListURL,
      assigneeType: '1',
      assigneeTypeOps: [
        {
          label: '指定人',
          value: '1'
        },
        {
          label: '发起人',
          value: '2'
        }
      ],
      assignmentDialogData: {
        assignee: '',
        candidateGroups: undefined,
        candidateUsers: undefined
      },
      assignmentDialogVisible: false,
      assignmentDialogCB: undefined,
      designDialogVisible: false,
      workFlowDesignSrc: '',
      updateUserParams: {
        searchUrl: getUserListURL,
        request: {
          text: 'keyword',
          value: 'id'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      },
      flowTypeOps: setFlowTypeOps(1),
      statusOps: setStatusOps(1),
      searchParams: {
        flowName: '',
        flowType: undefined,
        status: undefined,
        inuser: '',
        beginTime: '',
        endTime: '',
        beginUpdateTime: '',
        endUpdateTime: ''
      },
      headers: [
        {
          prop: 'flowName',
          label: '流程名称'
        },
        {
          prop: 'flowTypeText',
          label: '流程类型',
          formatter (row) {
            return flowTypeMap[row.flowType]
          }
        },
        {
          prop: 'statusText',
          label: '流程状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'inuserName',
          label: '创建人'
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'updateTime',
          label: '修改时间'
        }
      ]
    }
  },
  mounted () {
    window.getWorkFlowListComponent = () => {
      return this
    }
  },
  computed: {
    userType () {
      return this.$store.state.userInfo.userType
    }
  },
  methods: {
    createData () {
      this.$router.push({
        name: 'workFlowForm'
      })
    },
    updateData (row) {
      this.$router.push({
        name: 'workFlowForm',
        query: {
          id: row.id
        }
      })
    },
    design (row) {
      this.workFlowDesignSrc = 'static/workflow/static/modeler.html?modelId=' + row.actReModelId
      this.designDialogVisible = true
    },
    async save (params) {
      const { status, data } = await this.$axios.post(saveDesignURL, params)
      if (status === 100) {
        this.$message.success('保存成功')
        this.designDialogVisible = false
      }
    },
    async publish (row) {
      const id = row.id
      const url = `${deployDesignURL}?id=${id}`
      const { status, data } = await this.$axios.get(url)
      if (status === 100) {
        this.$message.success('发布成功')
        this.$refs.list.searchData()
      }
    },
    setAssignmentDialogCb (cb) {
      this.assignmentDialogCB = cb
    },
    setAssignmentDialogVisible (visible, assignee) {
      this.assignmentDialogVisible = visible
      if (assignee === '${workflowStartUserId}') {
        this.assigneeType = '2'
      } else {
        this.assigneeType = '1'
        this.assignmentDialogData.assignee = assignee
      }
    },
    resetAssignmentDialogData () {
      this.assignmentDialogData = {
        assignee: '',
        candidateGroups: undefined,
        candidateUsers: undefined
      }
    },
    assignmentDialogSave () {
      if (this.assignmentDialogCB) {
        const assignmentDialogData = {
          assignee: this.assigneeType === '1' ? this.assignmentDialogData.assignee : '${workflowStartUserId}',
          candidateGroups: this.assignmentDialogData.candidateGroups,
          candidateUsers: this.assignmentDialogData.candidateUsers,
        }
        this.assignmentDialogCB(assignmentDialogData)
      }
      this.resetAssignmentDialogData()
      this.assignmentDialogVisible = false
    },
    assignmentDialogClose (done) {
      this.resetAssignmentDialogData()
      done()
    },
    async handleClose (done) {
      const result = await this.$confirm('流程设计还没有保存，是否返回列表？')
      if (result) {
        done()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.WorkFlowList {
  ::v-deep {
    .assignment-dialog {
      .el-dialog__body {
        .assignment-dialog-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 20px;
        }
      }
    }
    .work-flow-design-dialog {
      display: flex;
      flex-direction: column;
      text-align: start;
      .el-dialog__body {
        flex: 1;
        padding: 0 0 5px 0;
        .work-flow-design-dialog-content {
          height: 100%;
        }
      }
    }
  }
}
</style>
