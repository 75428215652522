var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "WorkFlowList" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
          hasOperateColumn: _vm.userType == 101,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _vm.userType == 101
                  ? _c("v-button", {
                      attrs: { text: "新增", permission: "add" },
                      on: { click: _vm.createData },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "流程名称" },
                  model: {
                    value: _vm.searchParams.flowName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "flowName", $$v)
                    },
                    expression: "searchParams.flowName",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "流程类型", options: _vm.flowTypeOps },
                  model: {
                    value: _vm.searchParams.flowType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "flowType", $$v)
                    },
                    expression: "searchParams.flowType",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "流程状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "status", $$v)
                    },
                    expression: "searchParams.status",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "创建人" },
                      model: {
                        value: _vm.searchParams.inuser,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "inuser", $$v)
                        },
                        expression: "searchParams.inuser",
                      },
                    },
                    "v-select2",
                    _vm.updateUserParams,
                    false
                  )
                ),
                _c("v-datepicker", {
                  attrs: {
                    label: "创建时间",
                    type: "rangedatetimer",
                    startTime: _vm.searchParams.beginTime,
                    endTime: _vm.searchParams.endTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "beginTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "beginTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "修改时间",
                    type: "rangedatetimer",
                    startTime: _vm.searchParams.beginUpdateTime,
                    endTime: _vm.searchParams.endUpdateTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "beginUpdateTime",
                        $event
                      )
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "beginUpdateTime",
                        $event
                      )
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endUpdateTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endUpdateTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _vm.userType == 101
                  ? _c("v-button", {
                      attrs: {
                        text: "编辑",
                        permission: "update",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updateData(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.userType == 101
                  ? _c("v-button", {
                      attrs: {
                        text: "设计",
                        permission: "design",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.design(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.userType == 101
                  ? _c("v-button", {
                      attrs: {
                        text: "发布",
                        permission: "publish",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.publish(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "assignment-dialog",
            title: "审批人",
            "modal-append-to-body": false,
            visible: _vm.assignmentDialogVisible,
            "before-close": _vm.assignmentDialogClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.assignmentDialogVisible = $event
            },
          },
        },
        [
          _vm.assignmentDialogVisible
            ? _c(
                "div",
                { staticClass: "assignment-dialog-content" },
                [
                  _c("checkbox-plus", {
                    attrs: {
                      type: "radio",
                      value: _vm.assigneeType,
                      options: _vm.assigneeTypeOps,
                    },
                    on: {
                      "update:value": function ($event) {
                        _vm.assigneeType = $event
                      },
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.assigneeType == 1,
                          expression: "assigneeType == 1",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("选择指定人：")]),
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            staticStyle: { display: "inline-block" },
                            attrs: { width: 300 },
                            model: {
                              value: _vm.assignmentDialogData.assignee,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.assignmentDialogData,
                                  "assignee",
                                  $$v
                                )
                              },
                              expression: "assignmentDialogData.assignee",
                            },
                          },
                          "v-select2",
                          _vm.updateUserParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c("v-button", {
                attrs: { text: "保存" },
                on: { click: _vm.assignmentDialogSave },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "work-flow-design-dialog",
            title: "流程设计器",
            fullscreen: "",
            "modal-append-to-body": false,
            modal: false,
            visible: _vm.designDialogVisible,
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.designDialogVisible = $event
            },
          },
        },
        [
          _vm.designDialogVisible
            ? _c("div", { staticClass: "work-flow-design-dialog-content" }, [
                _c("iframe", {
                  ref: "workFlowDesign",
                  staticStyle: { border: "none" },
                  attrs: {
                    width: "100%",
                    height: "100%",
                    src: _vm.workFlowDesignSrc,
                  },
                }),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }